import { gql } from "@apollo/client";

export const GET_ALL_USERS_QUERY = gql`
  query GetAllUsers($filters: GetAllUsersFilter) {
    getAllUsers(filters: $filters) {
      id
      email
      name
      isAccountActivated
      creditsCurrencySymbol
      cuminCredits
    }
  }
`;

export const GET_ME_QUERY = gql`
  query GetMe {
    getMe {
      email
    }
  }
`;

export const GET_PENDING_SUBSCRIPTIONS_QUERY = gql`
  query GetAdminPendingSubscription {
    getAdminPendingSubscription {
      id
      user {
        name
      }
      shipping_address {
        id
        first_name
        last_name
        address_line_1
        address_line_2
        city
        state
        country_code
        phone_number
        zip_code
      }
      subscription_products {
        quantity
        product {
          title
        }
      }
    }
  }
`;

export const GET_ALL_FREE_ORDERS_QUERY = gql`
  query GetAdminAllFreeOrders {
    getAdminAllFreeOrders {
      id
      shipping_address {
        id
        first_name
        last_name
        address_line_1
        address_line_2
        city
        state
        country_code
        zip_code
        phone_number
      }
      trackingId
      order_products {
        product {
          title
        }
        quantity
      }
    }
  }
`;

export const GET_ALL_INVENTORY = gql`
  query getAllInventory($input: GetAdminInventoryInput!) {
    getAdminInventory(input: $input) {
      id
      dispatchDate
      boxNumber
      trackingNumber
      carrier
      productCode
      quantity
      batchNumber
      expireDate
      storageUnit
      status
      receivedDate
      stagedDate
      emptiedDate
      quarantineDate
      destroyedDate
      productTitle
      comments
      destination
      packType
    }
  }
`;

export const GET_INVENTORY_DESTINATIONS = gql`
  query {
    getAdminInventoryDestinations {
      id
      name
    }
  }
`;
