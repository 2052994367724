import { Box, Button, Input } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_INVENTORY } from "../../graphql/queries/user";
import { useCallback, useEffect, useState } from "react";
import _debounce from "lodash.debounce";
import { UPDATE_INVENTORY_DATA_MUTATION } from "../../graphql/mutation/user";
import StagedWithDestinationModal from "./StagedWithDestinationModal";
import Snackbar from "../../components/Snackbar";

const columns = [
  { field: "id", headerName: "ID" },
  { field: "dispatchDate", headerName: "Dispatch Date" },
  { field: "boxNumber", headerName: "Box Number" },
  { field: "trackingNumber", headerName: "Tracking Number" },
  { field: "carrier", headerName: "Carrier" },
  { field: "productCode", headerName: "Product Code" },
  { field: "quantity", headerName: "Quantity" },
  { field: "batchNumber", headerName: "Batch Number" },
  { field: "expireDate", headerName: "Expire Date" },
  { field: "storageUnit", headerName: "Storage Unit" },
  { field: "status", headerName: "Status" },
  { field: "receivedDate", headerName: "Received Date" },
  { field: "stagedDate", headerName: "Staged Date" },
  { field: "emptiedDate", headerName: "Emptied Date" },
  { field: "quarantineDate", headerName: "Quarantine Date" },
  { field: "destroyedDate", headerName: "Destroyed Date" },
  { field: "productTitle", headerName: "Product Title" },
  { field: "comments", headerName: "Comments" },
  { field: "destination", headerName: "Destination" },
  { field: "packType", headerName: "Pack Type" },
];

const InventoryTable = () => {
  const [searchProductCodeTerm, setSearchProductCodeTerm] = useState("");
  const [queryFilters, setQueryFilters] = useState({
    productCode: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalType, setModalType] = useState(null);
  const [openToaster, setOpenToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");

  const { data: inventoryData, loading: inventoryLoading } = useQuery(
    GET_ALL_INVENTORY,
    {
      variables: {
        input: {
          filters: queryFilters,
        },
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const [updateInventoryData, { loading }] = useMutation(
    UPDATE_INVENTORY_DATA_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ALL_INVENTORY,
          variables: {
            input: {
              filters: queryFilters,
            },
          },
        },
      ],
    }
  );
  const { getAdminInventory = [] } = inventoryData || {};

  const debounceSetQueryFilters = useCallback(
    _debounce((productCode) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        productCode,
      }));
    }, 300),
    []
  );

  useEffect(() => {
    debounceSetQueryFilters(searchProductCodeTerm);
  }, [searchProductCodeTerm]);

  const handleRowSelect = (ids) => {
    const id = ids[ids.length - 1];
    const rows = getAdminInventory.filter((i) => i.id === id);
    setSelectedRows(rows);
  };

  const handleAction = async (status) => {
    await updateInventoryData({
      variables: {
        input: {
          ids: selectedRows.map((i) => i.id),
          status,
        },
      },
    });
    setSelectedRows([]);
  };

  const handleActionModal = (modalType) => {
    setModalType(modalType);
  }

  const handleOpenToaster = (message) => {
    setOpenToaster(true);
    setToasterMessage(message);
  };

  const handleCloseToaster = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToasterMessage("");
    setOpenToaster(false);
  };

  return (
    <Box
      sx={{
        height: "100%",
        padding: "12px 0",
      }}
    >
      
      <Snackbar
        open={openToaster}
        handleClose={handleCloseToaster}
        message={toasterMessage}
      />
      {
        modalType === "Staged With Destination" ? <StagedWithDestinationModal
        open={true}
        handleClose={() => {
          setModalType(null)
          setSelectedRows([]);
        }}
        handleOpenToaster={handleOpenToaster}
        status="Staged"
        selectedRows={selectedRows}
        queryFilters={queryFilters}
      /> : null
      }
      <Input
        placeholder="Search Product Code or Box Number"
        value={searchProductCodeTerm}
        defaultChecked={searchProductCodeTerm}
        onChange={(e) => {
          const { value } = e.target;
          setSearchProductCodeTerm(value);
        }}
        name="productCode"
        type="search"
      />
      <Button
        onClick={() => {
          handleAction("Received");
        }}
        variant="contained"
        disabled={
          !selectedRows?.length ||
          inventoryLoading ||
          loading ||
          selectedRows?.[0]?.status === "Received"
        }
      >
        Mark Received
      </Button>
      <Button
        onClick={() => {
          handleAction("Staged");
        }}
        variant="contained"
        disabled={
          !selectedRows?.length ||
          inventoryLoading ||
          loading ||
          selectedRows?.[0]?.status === "Staged"
        }
      >
        Mark Staged
      </Button>
      <Button
        onClick={() => {
          handleAction("Emptied");
        }}
        variant="contained"
        disabled={!selectedRows?.length || inventoryLoading || loading}
      >
        Mark Emptied
      </Button>
      <Button
        onClick={() => {
          handleAction("Quarantined");
        }}
        variant="contained"
        disabled={!selectedRows?.length || inventoryLoading || loading}
      >
        Mark Quarantined
      </Button>
      <Button
        onClick={() => {
          handleAction("Destroyed");
        }}
        variant="contained"
        disabled={!selectedRows?.length || inventoryLoading || loading}
      >
        Mark Destroyed
      </Button>
      <Button
        onClick={() => {
          handleActionModal("Staged With Destination");
        }}
        variant="contained"
        disabled={
          !selectedRows?.length ||
          inventoryLoading ||
          loading ||
          selectedRows?.[0]?.status === "Staged"
        }
      >
        Mark Staged with Destination
      </Button>
      <DataGrid
        sx={{
          marginTop: "8px",
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
        }}
        loading={inventoryLoading || loading}
        rows={getAdminInventory}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        onSelectionModelChange={handleRowSelect}
        checkboxSelection
        selectionModel={selectedRows.map((i) => i.id)}
      />
    </Box>
  );
};

export default InventoryTable;
